<template>
    <div class="right_nav">
        <!-- <div class="top_bar">调查问卷</div> -->
        <!-- 
             -->
        <div
            class="bottom_bar"
            @mouseenter="show = true"
            @mouseleave="show = false"
        >
            <div
                class="consult"
                :class="kefucode ? 'kefucode' : ''"
                v-if="show"
            >
                <div style="float:left;margin-right:30px" v-if="kefucode">
                    <img src="../assets/images/kefucode.png" alt="">
                    <div>微信扫码 立即咨询</div>
                </div>
                <div style="float:left">
                    <img :src="$store.state.config.baseInfo.clientServiceQrCode" />
                    <div>微信扫码 加群咨询</div>
                </div>
                
            </div>
            <img
                :src="require('@/static/images/home/kefu.png')"
                class="kefu"
            />
        </div>
        <el-backtop
            class="backtop-layout"
            target="#__nuxt"
            icon="el-icon-arrow-up"
        ></el-backtop>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            show: false,
            kefucode:true,
        };
    },
    created() {},
    methods: {
        shh9() {
            dfdfdf;
        },
        isWorkTime() {
            const now = new Date();
            const week = now.getDay();
            const hour = now.getHours();
            const minute = now.getMinutes();

            // 节假日直接返回false
            const holidays = [
                '2025/1/1', 
                '2025/1/28', 
                '2025/1/29', 
                '2025/1/30', 
                '2025/1/31', 
                '2025/2/1', 
                '2025/2/2', 
                '2025/2/3', 
                '2025/2/4', 
                '2025/4/4', 
                '2025/4/5', 
                '2025/4/6', 
                '2025/5/1', 
                '2025/5/2', 
                '2025/5/3', 
                '2025/5/4', 
                '2025/5/5', 
                '2025/5/31', 
                '2025/6/1', 
                '2025/6/2', 
                '2025/10/1', 
                '2025/10/2', 
                '2025/10/3', 
                '2025/10/4', 
                '2025/10/5', 
                '2025/10/6', 
                '2025/10/7', 
                '2025/10/8', 
            ];
            if (holidays.includes(now.toLocaleDateString())) return false;

            // 周末属于补班日期
            const nWeekend = [
                '2025/1/26', 
                '2025/2/8', 
                '2025/4/27', 
                '2025/9/28', 
                '2025/10/11', 
            ];
            if (nWeekend.includes(now.toLocaleDateString())) {
                // 正常上班时间
                if(hour > 8 &&  hour < 11 || hour > 13 && hour < 17){
                    return true;
                }else if(hour == 8 || hour == 11 || hour == 13 || hour == 17){
                    if(hour == 8 || hour == 13){
                        if(minute > 30){
                            return true;
                        }
                    }else{
                        if(minute < 30){    
                            return true;
                        }
                    }
                }
            }

            // 正常上班时间
            if (week > 0 && week < 6) {
                if(hour > 8 &&  hour < 11 || hour > 13 && hour < 17){
                    return true;
                }else if(hour == 8 || hour == 11 || hour == 13 || hour == 17){
                    if(hour == 8 || hour == 13){
                        if(minute > 30){
                            return true;
                        }
                    }else{
                        if(minute < 30){    
                            return true;
                        }
                    }
                }
            }

            return false;
        }
    },
    mounted() {
        var this_ = this;     
        this_.kefucode = this_.isWorkTime();
        // 每分钟请求一次是否在工作时间
        setInterval(function() {            
            this_.kefucode = this_.isWorkTime();
        }, 60000);
    },
    computed: {},
};
</script>
<style scoped lang="scss">
@import '@css/var.scss';

.right_nav {
    position: fixed;
    right: 20px;
    bottom: 98px;
    z-index: 99;

    .top_bar {
        width: 48px;
        height: 135px;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        text-align: center;
        line-height: 48px;
        letter-spacing: 8px;
        color: #ffffff;
        background: $primaryColor;
        writing-mode: vertical-lr;
        border-radius: 2px;
        cursor: pointer;
    }

    .bottom_bar {
        width: 48px;
        height: 48px;
        margin-top: 8px;
        background: $primaryColor;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        cursor: pointer;
        position: relative;
    }

    .backtop-layout {
        right: 20px !important;
        width: 48px;
        height: 48px;
        cursor: pointer;

        box-shadow: 0px 0px 7px 1px rgba(179, 179, 179, 0.2);
        border-radius: 2px;
    }

    .consult {
        width: 169px;
        height: 186px;
        position: absolute;
        right: 73px;
        bottom: -55px;
        background: #fff;
        color: #575d6c;
        font-size: 14px;
        line-height: 20px;
        padding: 22px;
        border: solid 1px #e6edf5;

        img {
            width: 117px;
            height: 116px;
        }
    }
    .kefucode{
        width: 320px;
    }
    ::v-deep {
        .el-icon-caret-top:before {
            content: '';
            color: #575d6c;
        }
    }
}
</style>
